<template>
  <div class="vx-col w-full md:w-1/2 freez md:pr-4">
    <vx-card>
      <img
        :src="require('@/assets/images/freeze ECG (s).jpg')"
        alt="content-img"
        class="responsive rounded-lg"
      />
      <p class="mt-5">
        <span> Your ECG Balance : </span>
        <span :class="ecsBalance > 0 ? 'text-success' : 'text-danger'">{{
          ecsBalance
        }}</span>
      </p>
      <section>
        <!-- Amount -->
        <div class="input-holder">
          <vs-input
            label="Amount (ECG)"
            v-model="ecsAmount"
            class="mt-5 w-full mb-5"
            name="item-name"
            type="number"
            v-validate="'required'"
          />
          <vs-button
            size="small"
            class="max-ecs"
            @click="maxBalance"
            color="success"
            type="filled"
          >
            Max
          </vs-button>
        </div>
        <div class="flex justify-between flex-wrap">
          <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
            >Freez</vs-button
          >
        </div>
      </section>
    </vx-card>
  </div>
</template>

<script>
import { callContractMixin } from "@/mixins/callCotractMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      ecsAmount: "",
      ecsBalance: ""
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      getDefaultAddress: "tronBot/getDefaultAddress",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      user: "auth/user"
    }),
    //

    isFormValid() {
      return (
        !this.errors.any() &&
        this.ecsAmount &&
        parseInt(this.ecsAmount) <= parseInt(this.ecsBalance)
      );
    }
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getDashboardData: "auth/getDashboardData",
      freeze: "modulePayment/freeze"
    }),
    //

    // Get ECS Balance Of
    async getECSBalanceOf() {
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "balanceOf",
        [this.getDefaultAddress.base58],
        res => {
          if (res) {
            let ecsBalance = parseInt(res._hex);
            this.ecsBalance = this.getTronWebObject.fromSun(ecsBalance);
          }
        },
        false
      );
    },
    //

    // Pursue Transaction
    pursueTransaction(hash) {
      this.$vs.notify({
        title: "Persure Transaction",
        text: "Click Here To Persure Transaction",
        color: "primary",
        click: () => {
          window.open(`https://tronscan.org/#/transaction/${hash}`, "_blank");
        }
      });
    },
    //

    // Freez
    freezECS(ecs) {
      let payParam = {
        userId: this.user.id,
        token: this.getTronWebObject.fromSun(ecs)
      };
      this.freeze(payParam).then(res => {
        if (res) {
          this.getDashboardData();
          this.getECSBalanceOf();
          this.initValues();

          // Notify
          this.$vs.notify({
            title: "Done",
            text: "Request Done",
            color: "success",
            iconPack: "feather",
            icon: "icon-check",
            position: "top-right"
          });
        }
      });
    },
    //

    // Freez ECS
    async payECS() {
      let ecs = this.getTronWebObject.toSun(this.ecsAmount);
      await this.callContract(
        this.contractInfo.ecsContractAddress,
        "allowance",
        [this.getDefaultAddress.base58, this.contractInfo.contractAddress],
        res => {
          if (res >= +ecs) {
            this.freezECS(ecs);
          } else {
            this.callContract(
              this.contractInfo.ecsContractAddress,
              "approve",
              [this.contractInfo.contractAddress, ecs],
              res => {
                if (res) {
                  this.freezECS(ecs);
                }
              },
              true
            );
          }
        },
        false
      );
    },
    //

    initValues() {
      this.ecsAmount = "";
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.payECS();
        }
      });
    },

    maxBalance() {
      this.ecsAmount = this.ecsBalance;
    }
  },
  mounted() {
    // Get Base Info
    setTimeout(() => {
      this.getECSBalanceOf();
    }, 2000);
  },
  watch: {
    getTronWebObject() {
      if (this.getTronWebObject) {
        this.getECSBalanceOf();
      }
    }
  },
  mixins: [callContractMixin]
};
</script>
