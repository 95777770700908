<template>
  <div id="freez-ecs">
    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="CreditCardIcon"
          icon-right
          :statistic="dashboard.myEcsPoolShare"
          statisticTitle="Your ECG Pool Share"
        />
      </div>

      <div class="vx-col w-full md:w-1/2">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="Share2Icon"
          icon-right
          :statistic="dashboard.totalEcsPoolShares"
          statisticTitle="Total ECG Pool Share"
          color="warning"
        />
      </div>
    </div>
    <div class="vx-row">
      <EcsPoll />
      <div class="vx-col w-full lg:w-2/3 flex flex-wrap card-box">
        <FreezCard />
        <UnfreezCard />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import FreezCard from "@/components/freez-ecs/FreezCard.vue";
import UnfreezCard from "@/components/freez-ecs/UnfreezCard.vue";
import EcsPoll from "@/components/dashboard/EcsPoll.vue";
export default {
  components: {
    StatisticsCardLine,
    FreezCard,
    UnfreezCard,
    EcsPoll
  },
  computed: {
    ...mapGetters({
      dashboard: "auth/dashboard"
    })
  },
  methods: {
    // Vuex Map Mutations
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getDashboardData: "auth/getDashboardData"
    })
    //
  },
  async mounted() {

    // Start Loading
    this.$vs.loading();
    //
    await this.getBaseInfo()
    await this.getDashboardData();
    // Close Loading
    this.$vs.loading.close();
    //
  }
};
</script>

<style lang="scss" scope>
@import "./freez.scss";
</style>
