<template>
  <div class="vx-col w-full md:w-1/2 un-freez mt-5 md:mt-0 md:pl-4">
    <vx-card>
      <img
        :src="require('@/assets/images/Unfreeze ECG (s).jpg')"
        alt="content-img"
        class="responsive rounded-lg"
      />
      <p class="mt-5">
        <span> Your ECG Freeze : </span>
        <span
          :class="
            dashboard.myEcsInvestment > 0 ? 'text-success' : 'text-danger'
          "
          >{{ dashboard.myEcsInvestment }}</span
        >
      </p>

      <p>
        <span> Unfreeze Fee : </span>
        <span
          class="text-danger"
          >{{ contractInfo.withdrawEcsFeeAmount }}</span
        >
      </p>
      <section>
        <!-- Amount -->
        <div class="input-holder">
          <vs-input
            label="Amount (ECG)"
            v-model="ecsAmount"
            class="mt-5 w-full mb-5"
            name="item-name"
            type="number"
            v-validate="'required'"
          />
          <vs-button
            size="small"
            class="max-ecs"
            @click="maxBalance"
            color="success"
            type="filled"
          >
            Max
          </vs-button>
        </div>
        <div class="flex justify-between flex-wrap">
          <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid"
            >Unfreeze</vs-button
          >
        </div>
      </section>
    </vx-card>
  </div>
</template>

<script>
import { callContractMixin } from "@/mixins/callCotractMixin";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      ecsAmount: "",
      ecsBalance: ""
    };
  },
  computed: {
    // Vuex Map Getters
    ...mapGetters({
      getDefaultAddress: "tronBot/getDefaultAddress",
      getTronWebObject: "tronBot/getTronWebObject",
      //
      contractInfo: "contract/contractInfo",
      //
      dashboard: "auth/dashboard"
    }),
    //

    isFormValid() {
      return (
        !this.errors.any() &&
        this.ecsAmount &&
        parseInt(this.ecsAmount) <= parseInt(this.dashboard.myEcsInvestment)
      );
    }
  },
  methods: {
    // Vuex Map Actions
    ...mapActions({
      getBaseInfo: "contract/getBaseInfo",
      getDashboardData: "auth/getDashboardData",
      unFreez: "modulePayment/unFreez"
    }),
    //

    initValues() {
      this.ecsAmount = "";
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.unFreez({
            amount: this.ecsAmount
          }).then(res => {
            if (res) {
              this.$vs.notify({
                title: "un freez",
                text: "un freez Request Send",
                iconPack: "feather",
                icon: "icon-check",
                color: "success",
                position: "top-right"
              });

              this.initValues();
              this.getDashboardData();
            }
          });
        }
      });
    },

    maxBalance() {
      this.ecsAmount = this.dashboard.myEcsInvestment;
    }
  },
  mixins: [callContractMixin]
};
</script>
